.agency-page {
  background-color: transparent;

  .agency{
    h1 {
      padding-top: 0;
      font-size: 70px;
    }

    p {
      font-size: 20px;
    }
  }

  section {
    .container {
      flex-basis: 100%;
    }
  }

  .header {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .burger-btn:before,
  .burger-btn:after {
    background-color: #000;
  }
}

.whatwedo {
  @include media(">=middle") {
    h2 {
      font-size: 68px;
    }
    p {
      font-size: 19px;
    }
  }
}

.things {
  @include media(">=middle") {
    h2 {
      font-size: 34px;
    }
  }
}

.agency,
.whatwedo,
.things,
.roadmap {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 5;
  }
}
