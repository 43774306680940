.advertiser-page-first-block{
	text-align: center;
	padding: 90px 0 10px;
	@include media(">=middle") {
		padding: 62px 0 0 0;
		.container{
			height: auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-content: center;
				.content-wrapper{
					padding: 0;
					margin-bottom: -25px;
				}
		}
	}
  h2 {
    letter-spacing: 0.82px;
    font-size: 24px;
    @include media(">=middle") {
      font-size: 38px;
      letter-spacing: 1.36px;
    }
  }
	.subtitle{
		margin: 25px 0;
		letter-spacing: 0.71px;
    font-family: $poppins;
    line-height: 22px;
		@include media(">=middle") {
			font-size: 23px;
			line-height: 36px;
			margin: 9px 0 40px;
      letter-spacing: 1.26px;
		}
		@include media(">=hd") {
			font-size: 25px;
			margin: 9px 0 51px;
		}
	}
}

.advertiser-page-first-block-wrap{
	@include media(">=hd") {
		justify-content: center;
	}
}

.advertiser-page-first-block-item{
	margin-bottom: 28px;
	text-align: center;
	width: 50%;
	@include media(">=middle") {
		margin-bottom: 42px;
		width: 33%;
	}
	@include media(">=hd") {
		margin-bottom: 56px;
		width: 26%;
	}
	h3{
		margin-bottom: 15px;
		line-height: 1;
		letter-spacing: 0.99px;
    @include media(">=middle") {
      font-size: 20px;
    }
	}
	img{
		@include media(">=middle") {
			width: 70px;
		}
		@include media(">=hd") {
			width: 112px;
		}
	}
}
