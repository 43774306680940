// Mobile first
	$breakpoints: (
		'mobile-xs': 360px,
		'mobile-landscape': 540px,
		'tablet': 768px,
		'middle': 992px,
		'laptop': 1200px,
		'hd': 1440px,
		'full-hd': 1600px,
		'super-hd': 1920px
	);

//Fonts
	$helvetica: 'Helvetica', sans-serif;;
	$lato: 'Lato', sans-serif;
  $poppins: 'Poppins', sans-serif;
  $cyrvetica: 'Cyrvetica';
