/* COMMON */
  *,
  a:hover,
  button:hover{
    cursor: url(../img/cursor.png), default !important;
  }
	*,
	*::before,
	*::after {
	    box-sizing: inherit;
	    margin: 0;
	}
	html {
	    box-sizing: border-box;
	    font-family: $helvetica;
	}
	body {
	    margin: 0;
	    background-color: #000;
	    color: #fff;
	    font-size: 14px;
	    overflow: auto;
	    	.header {
	    		opacity: 0;
	    	}
	    &.animation-ended {
	    	.header {
	    		opacity: 1;
	    	}
	    }
    &.no-fixed-header {
      .header {
        opacity: 1;
      }
    }
	    @include media(">=mobile-xs") {
			font-size: 15px;
		}
	}
	section {
		position: relative;
		height: 100vh;
      &.one-section-scroll-disabled {
        height: auto;
      }
      .advertiser-page & {
        height: auto;
      }
	}
	.text-holder{
		    box-shadow: 0 0 100px 0 #000;
		    position: relative;
		    z-index: 6;
		    display: inline-block;
		    text-align: center;
		    background-color: rgba(0,0,0,.5);
		    border-radius: 500px;
		h1{
			display: inline-block;
		}
		p{
			display: inline-block;
		}
	}
	.container{
		box-sizing: border-box;
		margin-left: auto;
		margin-right: auto;
		padding-left: 30px;
		padding-right: 29px;
		width: 100%;
		height: 100%;
		.content-wrapper {
			background: rgba(0, 0, 0, 0.5);
			box-shadow: 0 0 100px 0 #000;
			position: relative;
			z-index: 6;
			display: inline-block;
			border-radius: 500px;
		}
		@include media(">=tablet") {
			padding-left: 40px;
			padding-right: 40px;
		}
		@include media(">=middle") {
			width: 900px;
		}
		@include media(">=laptop") {
			width: 1200px;
		}
		@include media(">=full-hd") {
			width: 1440px;
		}
	}
	img {
	    max-width: 100%;
	}

	nav{
		ul{
			padding-left: 0;
		}
		li{
			list-style-type: none;
			text-transform: uppercase;
		}
	}
	a {
	    text-decoration: none;
	}
	h2{
		font-family: $poppins;
		font-size: 24px;
		font-weight: 900;
		line-height: 1.39;
		letter-spacing: 0.92px;
		text-align: center;
		text-transform: uppercase;
		@include media(">=mobile-landscape") {
			font-size: 30px;
		}
		@include media(">=middle") {
			font-size: 33px;
		}
		@include media(">=hd") {
			font-size: 36px;
		}
	}
	h3{
		font-family: $lato;
		font-size: 14px;
		font-weight: bold;
		line-height: 1.43;
		letter-spacing: 0.55px;
		text-transform: uppercase;
		@include media(">=mobile-landscape") {
			font-size: 18px;
		}
		@include media(">=middle") {
			font-size: 20px;
		}
		@include media(">=hd") {
			font-size: 22px;
		}
	}
	button{
		cursor: pointer;
	}
	.btn-cta{
		font-family: $helvetica;
		border: none;
		color: #FFF;
		width: 94px;
		height: 30px;
		border-radius: 7px;
		background-color: #252525;
	}
	.flex{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
	}
	.reverse{
		color: #000;
	}
	.social-hrefs{
		img{
			width: 23px;
			height: 23px;
		}
	}
	/* PAGINATION */
	.onepage-pagination{
		top: 38%;
		max-width: 20px;
		@include media(">=middle") {
			left: 50px;
		}
		@include media(">=hd") {
			left: 126px;
		}
		&.black{
			li{
				a{
					&:before{
						background-color: #000;
					}
					&.active{
						&:before{
							background-color: #000;
						}
					}
				}
			}
		}
		li{
			z-index: 2;
			a{
				padding: 12.5px 0;
				&:before{
					background-color: #FFF;
				}
				&.active{
					width: 8px;
					height: 8px;
					&:before{
						left: -1px;
						border: none;
						background-color: #FFF;
					}
				}
			}
		}

	}
	/* VISIBLE & HIDE ELEMENTS */
	.visible-desktop{
		display: none;
		@include media(">=middle") {
			display: flex;
		}
	}
	.visible-laptop{
		display: none;
		@include media(">=laptop") {
			display: flex;
		}
	}
	.visible-hd{
		display: none;
		@include media(">=hd") {
			display: flex;
		}
	}
	.hidden-desktop{
		@include media(">=middle") {
			display: none;
		}
	}

	.scene {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
			section {
				position: fixed;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				display: block;
        flex-direction: column;
        align-items: center;
				opacity: 0;
				transition: opacity ease 1s;
        @include media(">=middle") {
          display: flex;
        }
          &.active-scene {
						opacity: 1;
						z-index: 5;
					}
        &:before {
          content: "";
          display: block;
          position: absolute;
          left: 0px;
          top: 0px;
          width: 100%;
          height: 100%;
          background: url(../img/dot.png) repeat;
          background-size: 12px;
          transition: 1000ms;
          transition-property: opacity;
          .agency-page & {
            display: none;
          }
        }
        &.light-bg {
          &:before {
            background: url(../img/dot-light.png) repeat;
            background-size: 12px;
          }
        }
			}
	}

.one-section-scroll-disabled {
  position: relative;
  header,
  .header {
    position: relative;
  }
  .header {
    padding-left: 0;
    padding-right: 0;
  }
  .scene {
    position: relative;
      section.one-section-scroll-disabled {
        position: relative;
      }
  }
}

select.js-select-to-change {
  display: none;
}

section {
  .container {
    height: auto;
  }
  .container.content {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.contact-us-page {
  section {
    height: auto;
  }
}

.thank-you {
  .last-block-wrap {
    position: relative;
    z-index: 10;
    margin-top: auto;
    margin-bottom: auto;
      h1 {
        margin-top: 0;
      }
  }
  .container {
    padding-top: 100px;
    padding-bottom: 100px;
    @include media(">=middle") {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .subtitle {
    text-align: center;
    font-size: 24px;
  }
  .footer {
    margin-top: 0;
  }
}

.last-block-wrap {
  @include media("highscreen") {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.scene .about2 .container.content {
  @include media("highscreen") {
    margin-top: 0;
    justify-content: center;
    .btn-cta{
      margin-top: 40px;
      margin-bottom: 0;
    }
  }
}
