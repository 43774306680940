.dots-nav {
  position: fixed;
  display: none;
  flex-direction: column;
  top: 50%;
  transform: translateY(-50%);
  left: 126px;
  z-index: 10;
  padding-left: 0;
  justify-content: center;
  align-items: center;
  &.black {
    button {
      background-color: #000;
    }
  }
  @include media(">=middle") {
    display: flex;
  }
  li {
    margin-bottom: 25px;
    list-style-type: none;
    display: flex;
    width: 8px;
    height: 8px;
    justify-content: center;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
  }
  button {
    display: block;
    width: 4px;
    height: 4px;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    padding: 0;
    transition: all ease .5s;
    outline: none;
      &.active {
        width: 8px;
        height: 8px;
      }
    .agency-page & {
      background-color: #000;
    }
  }
}
