/* EVENTS */
.scene section.events {
  justify-content: flex-start;
}
	.events{
		padding: 0;
		@include media(">=middle") {
			padding: 0;
			.last-block-wrap{
				height: auto;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-content: center;
					>.container {
						height: auto;
					}
			}
		}

		h2{
      margin-top: 36px;
      margin-bottom: 36px;
      line-height: 1;
			@include media(">=hd") {
				font-size: 44px;
				font-weight: 700;
			}
		}
		>.container{
			height: auto;
		}
		.btn-cta{
			padding: 0;
			font-family: $helvetica;
			text-shadow: 0 0 4px rgba(255, 255, 255, 0.5);
			text-transform: uppercase;
			font-size: 14px;
			border-radius: 7px;
			font-weight: 400;
			background-image: linear-gradient(98deg, #e04e4e 5%, #ee7731 98%);
			width: 138px;
			transition: ease 0.3s;
			position: relative;
			overflow: hidden;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      padding: 5px;
      min-height: 40px;
      text-align: center;
			&:before {
				content: "";
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: #fa4923;
				opacity: 0;
				z-index: 1;
				transition: ease 0.3s;
			}
			&:after {
			    content: "";
			    display: block;
			    width: 15px;
			    height: 300px;
			    margin-left: 60px;
			    background: #fff;
			    position: absolute;
			    left: -75px;
			    top: -150px;
			    z-index: 1;
			    transform: rotate(45deg);
			    transition: all 3s;
			    animation-duration: 3s;
			    animation-delay: 0.05s;
			    animation-timing-function: ease-in-out;
			    animation-direction: alternate;
			    animation-iteration-count: infinite;
			}
			&:hover, &:active, &:focus {
				background-image: linear-gradient(98deg, #ee7731 5%, #e04e4e 98%);
				&:after{
					animation-name: slideme;
				}
			}
		}
	}
	@-webkit-keyframes slideme {
		0% { left: -30px; margin-left: 0px; }
		30% { left: 110%; margin-left: 80px; }
		100% { left: 110%; margin-left: 80px; }
	}
	.event-item{
		&.unknown{
			.btn-cta{
				text-transform: none;
			}
			.event-info{
				img{
				    top: 12%;
				    left: 50%;
				    transform: translate(-50%);
				    @include media(">=mobile-xs") {
				    	top: 16%;
					}
				}
			}
		}
	}
	.event-info{
		align-items: center;
		width: 196px;
		position: relative;
		padding: 20px 0 50px 0;
		box-sizing: border-box;
		color: #FFF;
    border: 4px solid #80a4de;
		border-image-source: url(../img/event-border.svg);
		border-image-slice: 40;
		border-image-width: 40px;
		border-image-outset: 0;
		border-image-repeat: round round;
		margin: 0 auto 25px auto;
		height: 210px;
		@include media(">=mobile-xs") {
			max-width: 196px;
			height: 235px;
		}
		img{
			position: absolute;
			display: block;
			top: 50%;
			transform: translateY(-65%);
			z-index: 2;
			left: -15px;
			max-width: 120%;
		}
	}
	.event-caption{
		text-align: center;
		justify-content: center;
		position: absolute;
		height: 40px;
		left: -5px;
		right: -5px;
		bottom: -5px;
		background-color: #FFF;
		border-radius: 0 0 40px 40px;
		color: #000;
		font-size: 12px;
		font-family: $poppins;
		line-height: 1.14;
		letter-spacing: 0.9px;
		padding-left: 10px;
		padding-right: 10px;
		@include media(">=mobile-xs") {
			height: 45px;
		}
		&.delayed{
			text-transform: uppercase;
			font-weight: bold;
			font-size: 16px;
		}
	}
	.swiper2-container{
		overflow: hidden;
		position: relative;
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
		@include media(">=full-hd") {
			margin-bottom: 78px;
		}
    .event-next {
      display: none;
    }
	}
	.swiper-button-next{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		z-index: 3;
		width: 16px;
		height: 16px;
		cursor: pointer;
		img{
			width: 16px;
			height: 16px;
		}
	}
