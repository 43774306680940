@include media("<middle") {
  body > header {
    display: block;
  }
  .scene {
    position: relative;
    width: 100vw;
      section {
        position: relative;
        opacity: 1;
        width: 100%;
        height: auto;
        min-height: 100vh;
        display: block;
        justify-content: center;
        align-items: center;
        .agency-page & {
          min-height: auto;
        }
        &.about2.light-bg{
          background-color: #fff;
          padding-top: 52px;
          padding-bottom: 60px;
          min-height: auto;
          &::before {
            display: none;
          }
        }
        header {
          display: none;
        }
      }
  }
  .text-holder {
    margin-top: 131px;
    .publishers-block & {
      margin-top: 0;
    }
  }
  .hero {
    h1 {
      margin-top: 138px;
      margin-bottom: 38px;
    }
  }
  .about {
    padding-top: 0;
    padding-bottom: 0;
    .scene & {
      min-height: auto;
    }

    &::before {
      display: none;
    }
    .container.content {
      position: relative;
      z-index: 10;
      width: 100%;
      height: auto;
    }
    h2 {
      letter-spacing: 0.77;
      margin-bottom: 15px;
    }
    p {
      padding-left: 10px;
      line-height: 21px;
      letter-spacing: 0.66px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .about-wrap {
    margin-top: 0;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .header {
    padding: 10px 20px;
    background-color: rgba(0,0,0,.5);
      .logo {
        width: 88px;
      }
  }
  .container .content-wrapper {
    display: block;
  }

  .benefits {
    padding-top: 54px;
    h2 {
      margin-top: 0px;
    }
  }

  .benefits-wrap {
    margin-top: 25px;
  }

  .benefit-item {
    margin-bottom: 13px;
    min-height: auto;
  }

  .review-txt {
    .company-member {
      position: relative;
      text-align: right;
      &::before {
        display: none;
      }
      .name {
        position: relative;
        &::before {
          content: '-';
          position: absolute;
          left: -10px;
          top: 0px;
        }
      }
    }
    &.review-1,
    &.review-2,
    &.review-3,
    &.review-4,
    &.review-5 {
      .company-member {
        bottom: 0;
        left: auto;
        right: 0;
        display: block;
      }
    }
  }

  .slider-rewievs {
    padding-bottom: 0;
    .swiper-button-next {
      top: 85px;
      right: -20px;
    }
  }

  .advertiser {
    padding-top: 54px;
      h2 {
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 1.22px;
        line-height: 29px;
      }
  }

  .advertiser-item {
    img {
      margin-top: 9px;
      margin-bottom: 6px;
    }
    h3 {
      margin-bottom: 0px;
    }
    p {
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.52px;
    }
  }

  .advertiser {
    padding-bottom: 0;
  }

  .steps {
    padding-top: 60px;
    h2 {
      letter-spacing: 0.72px;
      line-height: 29px;
    }
  }

  .steps-wrap {
    margin-top: 27px;
  }

  .step-item {
    width: 240px;
    &.one,
    &.two,
    &.three,
    &.four {
      h3 {
        margin-left: 42px;
        letter-spacing: 1.3px;
      }
      .number {
        left: 1px;
      }
    }
    &.one {
      p {
        span {
          margin-left: 31px;
        }
      }
    }
    &.two {
      margin-bottom: 24px;
    }
    &.three {
      .number {
        top: -5px;
      }
      p span {
        margin-left: 25px;
      }
    }
    &.four {
      .number {
        top: -5px;
      }
      p {
        margin-left: 17px;
      }
    }
    p {
      text-align: left;
      margin-left: 17px;
      span {
        margin-left: 25px;
      }
    }
  }

  .about2 {
    padding-top: 52px;
    .container.content {
      margin-top: 0;
      position: relative;
      z-index: 10;
    }
    h2 {
      margin-bottom: 16px;
    }
    p {
      margin-bottom: 21px;
    }
    .btn-cta {
      margin-top: 24px;
    }
  }

  .events {
    padding-top: 60px;
    padding-bottom: 0px;
    .container.content {
      position: relative;
      z-index: 10;
    }
    .btn-cta {
      width: 120px;
      height: 30px;
    }
    .swiper-button-next {
      top: 40%;
    }
  }

  .event-info {
    margin-bottom: 22px;
    border-radius: 39px;
    border: 4px solid #80a4de;
  }

  .event-item.unknown .btn-cta {
    width: 150px;
  }

  .swiper2-container {
    margin-top: 25px;
    .event-next {
      display: block;
    }
  }

  .footer {
    margin-top: 68px;
    padding-top: 32px;
    padding-bottom: 37px;
    nav {
      margin-top: 0;
      text-align: left;
        li {
          margin-left: 0;
          margin-bottom: 14px;
        }
    }
  }

  .footer-connect {
    margin-left: auto;
    margin-right: auto;
    margin-top: 33px;
  }

  .footer-social {
    p {
      text-align: center;
    }
  }

  .footer-btns {
    flex-direction: column;
    margin-bottom: 38px;
    button {
      margin-bottom: 12px;
    }
  }
}
