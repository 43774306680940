@font-face {
    font-family: 'Cyrvetica';
    src: url('../fonts/cyrveticav2extraoutline-normal.eot?#iefix') format('embedded-opentype'),  url('../fonts/cyrveticav2extraoutline-normal.woff') format('woff'), url('../fonts/cyrveticav2extraoutline-normal.ttf')  format('truetype'), url('../fonts/cyrveticav2extraoutline-normal.svg#cyrveticav2extraoutline-normal') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* font-family: "Helvetica Light"; */
@font-face {
  font-family: "Helvetica";
  src: url("../fonts/HelveticaLight.eot");
  src: url("../fonts/HelveticaLight.eot?#iefix")format("embedded-opentype"),
  url("../fonts/HelveticaLight.woff") format("woff"),
  url("../fonts/HelveticaLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

/* font-family: "Helvetica Regular"; */
@font-face {
  font-family: "Helvetica";
  src: url("../fonts/HelveticaRegular.eot");
  src: url("../fonts/HelveticaRegular.eot?#iefix")format("embedded-opentype"),
  url("../fonts/HelveticaRegular.woff") format("woff"),
  url("../fonts/HelveticaRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

/* font-family: "Helvetica Bold"; */
@font-face {
  font-family: "Helvetica";
  src: url("../fonts/HelveticaBold.eot");
  src: url("../fonts/HelveticaBold.eot?#iefix")format("embedded-opentype"),
  url("../fonts/HelveticaBold.woff") format("woff"),
  url("../fonts/HelveticaBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}


