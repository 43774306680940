/* POLICY */
    .policy{
        padding: 45px 0 70px;
        font-size: 15px;
        line-height: 1.2;
        @include media(">=tablet") {
            font-size: 12px;
        }
        @include media(">=laptop") {
            font-size: 13px;
        }
        h1, h2{
            margin: 0;
            margin: 0 0 40px;
            font-weight: 800;
            text-transform: uppercase;
            color: #71757a;
        }
        h3{
            color: #71757a;
            font-weight: 800;
            margin: 0;
        }
        p{
            margin-bottom: 30px;
            color: #7A7A7A;
            &.policy-end{
                text-align: center;
            }
            span{
                font-weight: 800;
            }
        }
        span{
            color: #7A7A7A;
        }
        ul{
            margin-top: 0;
        }
        li{
            color: #71757a;
        }
    }