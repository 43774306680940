.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  right: 0;
  background-color: #000;
  transition: opacity ease .5s;
}

.preloader-img {
  max-width: 150px;
  width: 25%;
  animation-name: preloader;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

@keyframes preloader {
  0% {opacity: 1; transform: scale(.7);}
  50% {opacity: .9; transform: scale(1);}
  100% {opacity: 1; transform: scale(.7);}
}
