/* ADVERTISER */
	.advertiser{
		padding: 35px 0 20px;
		@include media(">=middle") {
			padding: 0;
			padding-top: 0;
      .advertiser-page & {
        padding-top: 79px;
      }
			.container{
				height: auto;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-content: center;
          &.content {
            margin-top: 64px;
          }
          .content-wrapper {
            max-width: 1074px;
            margin-left: auto;
            margin-right: auto;
          }
			}
		}
    @include media("highscreen") {
      .container.content {
        margin-top: auto;
      }
    }
    h2 {
      margin-bottom: 51px;
      font-size: 38px;
      line-height: 54px;
      letter-spacing: 1.39px;
    }
	}
	.advertiser-wrap{
		justify-content: center;
		align-items: flex-start;
		@include media(">=mobile-landscape") {
			justify-content: space-between;
			padding: 0;
		}
	}
	.advertiser-item{
		text-align: center;
		flex-direction: column;
    margin-right: 6px;
    &:last-child {
      margin-right: 0;
    }
		@include media(">=mobile-landscape") {
			width: 45%;
		}
		@include media(">=middle") {
			width: 24%;
		}
    @include media(">=laptop") {
      width: 264px;
    }
		img{
			margin: 10px 0 10px;
			width: 42px;
			height: 42px;
			@include media(">=mobile-xs") {
				width: 70px;
				height: 70px;
			}
			@include media(">=mobile-landscape") {
				width: 80px;
				height: 80px;
			}
			@include media(">=hd") {
				margin-bottom: 23px;
				width: 96px;
				height: 96px;
			}
		}
    h3 {
      letter-spacing: 1.35px;
      margin-bottom: 8px;
      font-size: 21px;
    }
		p{
			margin: 5px 0 10px;
			font-family: $lato;
			font-size: 14px;
			font-weight: 500;
			line-height: 1.4375;
			letter-spacing: 0.59px;
			text-align: center;
			max-width: 240px;
			@include media(">=middle") {
				font-size: 12px;
			}
			@include media(">=hd") {
				font-size: 16px;
				max-width: 264px;
			}
		}
	}
