/* ABOUT */
	.about{
		position: relative;
		color: #000;
		padding: 36px 0 13px;
		@include media(">=middle") {
			padding: 0;
			.container{
				height: auto;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-content: center;
        position: relative;
        z-index: 5;
			}
		}
		@include media("mac") {
			.container {
				height: auto;
			}
		}
    header {
      margin-top: 15px;
    }
		h2{
			font-size: 34px;
			line-height: 1.2;
			letter-spacing: 1.44px;
			margin-bottom: 22px;
			@include media(">=mobile-xs") {
				font-size: 40px;
			}
			@include media(">=mobile-landscape") {
				font-size: 47px;
			}
			@include media(">=tablet") {
				font-size: 54px;
			}
			@include media(">=middle") {
				font-size: 61px;
			}
			@include media(">=hd") {
				font-size: 66px;
				font-weight: 700;
				line-height: 1;
				letter-spacing: 2.45px;
				margin-bottom: 37px;
			}
		}
		p{
			font-size: 13px;
			line-height: 28px;
			letter-spacing: 0.91px;
			margin-bottom: 30px;
      font-weight: 300;
			@include media(">=mobile-xs") {
				font-size: 14px;
			}
			@include media(">=mobile-landscape") {
				font-size: 15px;
				text-align: center;
				margin: 0 auto 30px;
				max-width: 95%;
			}
			@include media(">=tablet") {
				font-size: 16px;
			}
			@include media(">=middle") {
				font-size: 17px;
        max-width: 920px;
			}
		}
		@include media(">=laptop") {
			left: 0;
		}
		.container{
      &.content {
        margin-bottom: 0;
        background-color: #fff;
        height: 80vh;
        margin-left: 0;
        width: 85vw;
      }
			@include media(">=laptop") {
				margin-left: 5px;
			}
		}
	}
	.about-wrap{
		position: relative;
		margin-top: auto;
		margin-bottom: auto;
	}
