.affiliates-page {
  .container {
    position: relative;
  }
}


@include media("<middle") {
  .scene section.publishers-block {
    min-height: auto;
    padding-bottom: 80px;
  }
}


.publishers-block {
  .container.content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  h1 {
    font-family: $poppins;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 24px;
    font-weight: 700;
    margin-top: 110px;
    @include media(">=middle") {
      font-size: 46px;
      margin-top: auto;
    }
    & + p {
      max-width: 100%;
    }
  }
  .subtitle {
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.85px;
    min-height: 175px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
      span {
        font-weight: bold;
      }
    @include media(">=middle") {
      font-size: 19px;
      line-height: 35px;
      letter-spacing: 1.33px;
    }
  }
  .cta-text {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 28px;
    margin-top: 43px;
    margin-bottom: 29px;
    letter-spacing: 1.33px;
    text-align: center;
    @include media(">=middle") {
      font-size: 18px;
      letter-spacing: 1.41px;
      margin-top: 0;
      margin-bottom: 33px;
    }

  }
  .btn-cta {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 36px;
    border-radius: 15px;
    background-image: linear-gradient(106deg, #1c0595 -50%, #b5e9ff 127%);
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.41px;
    text-align: center;
    color: #ffffff;
    margin-left: auto;
    margin-right: auto;
      span {
        margin-top: 3px;
      }
    @include media(">=middle") {
      width: 174px;
      height: 45px;
      font-size: 20px;
    }
  }
}

.become-affiliate {
  h2 {
    letter-spacing: 0.99px;
    @include media(">=middle") {
      letter-spacing: 2.54px;
      font-size: 33px;
    }
  }
  .container .content-wrapper {
    display: block;
  }
}

.become-affiliate-wrapper {
  padding-top: 23px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  @include media("mac") {
    padding-top: 25px;
  }
  @include media(">=middle") {
    padding-top: 45px;
  }
}

.become-affiliate-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  max-width: 100%;
  width: 466px;
  text-align: center;
  @include media("mac") {
    margin-bottom: 15px;
  }
  @include media(">=middle") {
    margin-bottom: 30px;
  }
  img {
    width: 42px;
    height: 42px;
    margin-bottom: 10px;
    @include media("mac") {
      margin-bottom: 10px;
    }
    @include media(">=middle") {
      width: 96px;
      height: 96px;
      margin-bottom: 18px;
    }
  }
  h3 {
    font-size: 15px;
    line-height: 24px;
    font-weight: bold;
    letter-spacing: 1.06px;
    margin-top: 0;
    margin-bottom: 8px;
    @include media("mac") {
      margin-bottom: 10px;
    }
    @include media(">=middle") {
      font-size: 22px;
      letter-spacing: 1.83px;
      margin-bottom: 17px;
    }

  }
  p {
    font-family: $lato;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.52px;
    @include media(">=middle") {
      font-size: 15px;
      line-height: 23px;
      letter-spacing: 0.96px;
    }
  }
}

.payment-methods-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 35px;
  @include media(">=middle") {
    margin-top: 40px;
  }
}

.payment-method-item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 33%;
  @include media("<tablet") {
    width: 50%;
  }
  @include media(">=middle") {
    justify-content: center;
  }

}

.payment-method-icon {
  width: 111px;
  height: 43px;
  background-color: #fff;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
  img {
    max-width: 80%;
    display: block;
    margin: auto;
    @include media(">=middle") {
      max-width: 100%;
    }
  }
  @include media("mac") {
    margin-bottom: 30px;
  }
  @include media(">=middle") {
    margin-bottom: 60px;
    width: 254px;
    height: 98px;
    border-radius: 22px;
  }
}
