body.white{
    background-color: #fff;
    color: #000;
    .container{
        padding: 0 35px;
    }
    section{
        height: auto;
        padding-bottom: 88px;
        &:last-child{
            padding-bottom: 0;
        }
    }
    header{
        background: transparent;
        .burger-btn:before,
        .burger-btn:after{
            background: #000;
        }
    }
}
.agency{
    text-align: center;
    padding-bottom: 94px;
  @include media(">=middle") {
    padding-bottom: 0;
  }
    h1{
        font-family: $poppins;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 52px;
        margin-top: 95px;
        padding-top: 95px;
        letter-spacing: 0.82px;
        @include media(">=middle"){
          font-size: 39px;
          margin-top: auto;
          letter-spacing: 2.45px;
        }
        @include media(">=hd"){
            font-size: 77px;
            padding-top: 188px;
        }
    }
    p{
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.85px;
        @include media(">=hd"){
            font-size: 22px;
            line-height: 38px;
            letter-spacing: 1px;
        }
        &:last-child{
            margin-top: 30px;
            font-weight: 700;
            letter-spacing: 1px;
            @include media(">=hd"){
                margin-top: 50px;
            }
        }
    }
}

.agency-txt__wrap{
    margin-left: auto;
    margin-right: auto;
    @include media(">=middle"){
        width: 822px;
    }
}
.whatwedo{
  text-align: center;
  padding-bottom: 114px;
    h2{
        margin-bottom: 20px;
        @include media(">=middle"){
          font-size: 36px;
          margin-bottom: 40px;
          padding-bottom: 0;
        }
        @include media(">=hd"){
            font-size: 72px;
            margin-bottom: 53px;
        }
    }
    p{
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.85px;
        @include media(">=hd"){
            font-size: 20px;
            line-height: 45px;
            width: 1000px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
.things{
  padding-bottom: 60px;
  @include media(">=middle") {
    padding-bottom: 0;
  }
    h2{
      padding: 0;
      letter-spacing: 0.77px;
      font-size: 20px;
        @include media(">=mobile-landscape"){
            padding: 0 31px;
            letter-spacing: 0.92px;
        }
        @include media(">=tablet"){
            padding: 0 50px;
        }
        @include media(">=middle"){
          font-size: 36px;
          letter-spacing: 1.39px;
        }
    }
}
.things__wrapper{
    flex-wrap: wrap;
    margin-top: 16px;
    align-items: flex-end;
    @include media(">=mobile-landscape"){
        margin-top: 40px;
    }
    @include media(">=tablet"){
        margin-top: 50px;
    }
    @include media(">=hd"){
        margin-top: 61px;
    }
    @include media("mac") {
      margin-top: 30px;
    }
}
.things__item{
    text-align: center;
    width: 50%;
    margin-bottom: 25px;
    @include media(">=tablet"){
        width: 25%;
    }
    @include media(">=hd"){
        margin-bottom: 33px;
    }
    @include media("mac") {
      margin-bottom: 10px;
    }
    img{
        @include media(">=middle"){
            width: 80px;
            height: 80px;
        }
        @include media(">=hd"){
            width: 134px;
            height: 134px;
        }
    }
    h3{
        margin-bottom: 10px;
        @include media(">=tablet"){
            font-size: 18px;
        }
        @include media(">=middle"){
            margin-bottom: 17px;
        }
    }
}
.roadmap{
    h2{
        margin-bottom: 27px;
        line-height: 1;
        font-size: 20px;
        letter-spacing: 0.77px;
        @include media(">=middle") {
          letter-spacing: 1.36px;
          margin-bottom: 0;
          margin-top: auto;
        }
        @include media(">=hd"){
            font-size: 40px;
        }
    }
}
.roadmap__item{
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 38px;
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
    width: 240px;
    @include media(">=mobile-xs"){
        width: 270px;
        margin-left: auto;
        margin-right: auto;
    }
    @include media(">=mobile-landscape"){
        width: 345px;
    }
    @include media(">=middle") {
      border-radius: 20px;
      margin-bottom: auto;
    }
    @include media(">=laptop"){
        width: 30%;
    }
    @include media(">=hd"){
        width: 345px;
    }
    h3{
        text-align: center;
        font-size: 15px;
        letter-spacing: 0.7px;
        text-transform: none;
        font-weight: 800;
        margin-top: 15px;
        @include media(">=hd"){
            font-size: 18px;
            margin-top: 20px;
        }
        @include media("mac") {
          margin-top: 10px;
        }
        + ul {
          padding-top: 15px;
          padding-right: 4px;
          @include media(">=middle") {
            padding-right: 10px;
          }
        }
    }
    ul{
        padding: 10px 10px 18px 34px;
        @include media(">=hd"){
            padding: 20px 37px 20px 63px;
        }
        li{
            margin-bottom: 20px;
            list-style-type: none;
            position: relative;
            line-height: 20px;
            letter-spacing: 0.66px;
            font-size: 13px;
            color: #000;
            @include media("mac") {
              margin-bottom: 5px;
            }
            @include media(">=middle") {
              font-size: 15px;
              line-height: 24px;
              letter-spacing: 0.7px;
            }
            &:before{
                content: '';
                position: absolute;
                background-image: url(../img/blue-dot.svg);
                background-repeat: no-repeat;
                background-size: cover;
                background-size: 10px 10px;
                left: -18px;
                top: 5px;
                width: 10px;
                height: 10px;
                @include media(">=hd"){
                    background-size: 16px 16px;
                    width: 16px;
                    height: 16px;
                    left: -30px;
                }
            }
            &:first-child{
                @include media(">=hd"){
                    margin-top: 20px;
                }
                @include media("mac") {
                  margin-top: 0;
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

.roadmap__item-header{
    justify-content: flex-start;
    align-items: center;
    background-image: linear-gradient(101deg, #1c0595 -49%, #b5e9ff 127%);
    color: #fff;
    border-radius: 38px 38px 0 0;
    padding: 5px 0 5px 28px;
    height: 45px;
  flex-wrap: nowrap;
    @include media(">=mobile-landscape"){
        height: 65px;
    }
    @include media("mac") {
      height: 50px;
      padding-top: 2px;
      padding-bottom: 2px;
    }
    @include media(">=middle") {
      border-radius: 20px 20px 0 0;
    }
    span{
        font-family: $cyrvetica;
        font-size: 35px;
        line-height: 0;
        margin-right: 20px;
        @include media(">=mobile-xs"){
            margin-right: 35px;
        }
        @include media(">=mobile-landscape"){
            font-size: 50px;
            margin-right: 50px;
        }
        @include media(">=laptop"){
            margin-right: 25px;
        }
        @include media(">=hd"){
            margin-right: 50px;
        }
    }
    p{
        text-transform: uppercase;
        line-height: 1.1;
        font-size: 15px;
        font-weight: 800;
        letter-spacing: 1.46px;
        text-align: center;
        @include media(">=mobile-landscape"){
            font-size: 19px;
        }
    }
}
.roadmap__wrap{
    position: relative;
    @include media(">=laptop"){
      display: flex;
      align-items: baseline;
      justify-content: space-around;
      flex-direction: row;
      width: 100%;
      margin: auto;
    }
}

.leave-request {
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 45px;

  p{
    max-width: 316px;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    letter-spacing: 0.55px;
    @include media(">=middle"){
      text-align: left;
      font-size: 15px;
      line-height: 25px;
      letter-spacing: 0.7px;
    }
  }
  img {
    width: 11px;
    height: 12px;
    margin-top: 32px;
    margin-bottom: 32px;
    @include media(">=middle") {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .btn-cta {
    width: 187px;
    height: 48px;
    border-radius: 17px;
    background-image: linear-gradient(98deg, #e04e4e 5%, #ee7731 98%);
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @include media(">=middle") {
    position: absolute;
    width: 616px;
    bottom: -20px;
    right: 0;
    flex-direction: row;
    margin-top: 0;
    margin-bottom: 0;
  }

}
