/* HERO */
	.hero {
		padding-bottom: 130px;
	    display: flex;
	    flex-direction: column;
	    header {
	      margin-top: 55px;
	    }
			@include media(">=middle") {
				padding: 0;
				.container{
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-content: center;
	          &.content {
	            height: auto;
	            margin-top: auto;
	            margin-bottom: auto;
	          }
				}
			}
	    .text-holder {
	    	margin-top: 0px;
        @include media(">=middle") {
          margin-top: -24px;
        }
	    }
	}
	h1{
		line-height: 1.12;
		letter-spacing: 1.7px;
		font-family: $cyrvetica;
		font-size: 50px;
    font-weight: 400;
		text-align: center;
		margin-bottom: 30px;
		margin-top: 146px;
		@include media(">=mobile-xs") {
			font-size: 60px;
		}
		@include media(">=tablet") {
			font-size: 78px;
		}
		@include media(">=middle") {
			font-size: 90px;
      letter-spacing: 3.7px;
      margin-top: 0;
		}
		@include media(">=laptop") {
			font-size: 110px;
		}
		@include media(">=hd") {
			margin-bottom: 41px;
		}
	}
	h1+p{
		text-align: center;
    font-size: 13px;
		line-height: 22px;
		letter-spacing: 0.29px;
		margin: 0 auto;
		@include media(">=mobile-landscape") {
			font-size: 16px;
			max-width: 95%;
		}
		@include media(">=middle") {
			max-width: 87%;
			font-size: 18px;
      line-height: 31px;
      letter-spacing: 0.44px;
		}
		@include media(">=laptop") {
			max-width: 800px;
			font-size: 20px;
		}
	}
