@import "../mixins/media";

.no-fixed-header {
  header,
  .header {
    position: fixed;
    @include media(">=middle") {
      position: relative;
    }
  }
}

section{
  .header {
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }
}

header{
	height: auto;
	z-index: 100;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
  height: 43px;
  body > & {
    display: none;
  }
  body.no-fixed-header > & {
    display: block;
  }
  .advertiser-page &,
  .contact-us-page &{
    margin-top: 17px;
      @include media("<middle") {
        margin-top: 0;
      }
  }
	@include media(">=middle") {
		height: auto;
	}
	@include media("mac") {
		height: auto;
	}
  section > & {
    position: relative;
    margin-top: 17px;
  }
}
.header{
	background-color: transparent;
	position: fixed;
	top: 0;
	left: 0px;
	right: 0px;
  padding: 0;
	width: 100%;
	z-index: 10;
	transition: color ease 1s, opacity ease 1s, background-color ease .3s;
  &.active {
    background-color: #000;
    .agency-page & {
      background-color: #fff;
    }
  }

  section > & {
    position: relative;
  }


	.letter {
		transition: fill 2s, stroke 2s;
		fill: #fff;
			&.letter-with-stroke {
				stroke: #fff;
			}
	}

	.reverse & {
		.letter {
			fill: #000;
			&.letter-with-stroke {
				stroke: #000;
			}
		}
		.btn-header {
			background-color: #000;
			color: #fff;
			border-color: #000;
		}
		.header-btns {
			.transparent {
				color: #000;
				border-color: #000;
			}
		}
	}

	@include media(">=middle") {
    margin-left: auto;
    margin-right: auto;
	}

  .no-fixed-header & {
    padding-left: 0;
    padding-right: 0;
  }
	&.flex{
		align-items: flex-end;
	}
	.visible-desktop{
		color: #fff;
		font-size: 16px;
		margin-bottom: 5px;
		ul{
			@include media(">=hd") {
				margin-left: 156px;
			}
		}
		li{
			text-transform: none;
			margin-left: 20px;
			font-weight: 400;
			letter-spacing: 0.74px;
			position: relative;
      &:last-child {
        margin-right: 0;
      }
			&:before{
				content: '';
				position: absolute;
				background-color: #FFF;
				width: 0px;
				height: 2px;
				bottom: -1px;
				transition: width ease .5s;
        .reverse & {
          background-color: #000;
        }
			}
			&:hover, &:active, &:focus{
				&:before{
					width: 100%;
				}
			}
			@include media(">=laptop") {
				font-size: 16px;
				margin-left: 0px;
				margin-right: 37px;
			}
		}
		a{
			color: #fff;
			transition: color 2s;
		}
	}
	.reverse & {
		a {
			color: #000;
		}
	}
	.logo{
		width: 125px;
		height: 30px;
		margin-left: 10px;
	}
}
.header-btns{
	margin-left: 0px;
	&.flex{
		justify-content: flex-end;
	}
	a.btn-header{
		border-radius: 7px;
		border: solid 2px #ffffff;
		background-color: #ffffff;
		position: relative;
		color: #000;
		font-size: 15px;
		font-weight: 400;
    display: flex;
		justify-content: center;
		letter-spacing: 0.3px;
		height: 28px;
		width: 106px;
		transition: background-color ease 1s, border-color ease 1s, color ease 1s;
		&:hover {
			box-shadow: 0px 0px 20px rgba(255,255,255,0.8);
		}
		&.transparent{
			border-radius: 7px;
			border: solid 2px #ffffff;
			background-color: transparent;
			color: #FFF;
			margin-left: 10px;
			letter-spacing: 0.35px;
			&:hover{
				border: 2px solid #FFF;
			}
		}
	}
}

/* Burger */
	.burger-btn{
		position: absolute;
		background-color: transparent;
		border: none;
		outline: none;
		right: 20px;
		width: 20px;
		height: 20px;
	    top: 50%;
	    transform: translateY(-50%);
	    padding: 0;
	    margin: 0;
		z-index: 5;
		&:before{
			content: '';
			position: absolute;
			width: 18px;
			height: 2px;
			background-color: #fff;
			border-radius: 2px;
			top: 6px;
			left: 0;
		}
		&:after{
			content: '';
			position: absolute;
			width: 18px;
			height: 2px;
			background-color: #fff;
			border-radius: 2px;
			top: 12px;
			left: 0;
		}
		&.active{
			&:before{
				transform: rotate(45deg);
				top: 10px;
			}
			&:after{
				transform: rotate(-45deg);
				top: 10px;
			}
		}
	}
	.overlay{
		background-color: rgba(0, 0, 0, .8);
	}

	.burger-menu{
		display: none;;
		position: fixed;
		padding-top: 53px;
		top: 53px;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #fff;
		color: #000;
		padding-left: 40px;
    padding-right: 40px;
		z-index: 4;
		transition: opacity ease .5s;
		button{
			margin-bottom: 10px;
			display: flex;
			margin-right: auto;
			border: none;
			padding: 0;
			background-color: transparent;
			text-transform: uppercase;
			font-size: 16px;
			font-weight: 700;
			font-family: $helvetica;
			letter-spacing: 0.86px;
			width: 100%;
		}
    a{
       text-transform: uppercase;
       font-size: 16px;
       letter-spacing: 0.86px;
        display: block;
      font-weight: 700;
      margin-bottom: 10px;
      color: #000;
     }
		nav{
			margin-top: 30px;
			padding-bottom: 10px;

			li{
				margin-bottom: 28px;
				a{
					color: #000;
				}
			}
		}

		.header-middle-icon{
			margin-left: 25px;
			margin-right: 25px;
		}

    .social-hrefs {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h3 {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.96px;
    }

    .burger-menu-caption {
      margin-top: 30px;
      text-align: center;
      font-size: 14px;
      letter-spacing: 0.15px;
      line-height: 24px;
        span {
          font-weight: 700;
        }
        a {
          text-transform: none;
          font-weight: 400;
        }
    }
	}
