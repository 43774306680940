/* BENEFITS */
	.benefits{
		padding: 59px 0 5px;
	    header {
	      margin-top: 15px;
	      @include media("mac") {
	        margin-top: 15px;
	          .logo {
	            height: 28px;
	          }
	      }
	    }
	    h2 {
	    	margin-top: 43px;
        @include media("mac") {
          margin-top: 21px;
        }
	    }
		@include media(">=middle") {
			padding: 0;
			.container{
				height: auto;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				align-content: center;
			}
		}
	    @include media("mac") {
	      .container.content {
	        margin-top: auto;
	        margin-bottom: auto;
	      }
	    }
	}
	.benefits-wrap{
		align-items: flex-start;
    justify-content: center;
		margin-top: 33px;
		margin-bottom: 12px;
		@include media(">=mobile-xs") {
			margin-left: 0;
			justify-content: center;
		}
		@include media("mac") {
			margin-top: 0;
      margin-bottom: 0;
		}
    @include media(">=middle") {
      margin-bottom: -7px;
    }
	}
	.benefit-item{
		margin-bottom: 15px;
    min-height: 100px;
		@include media(">=mobile-landscape") {
			margin-bottom: 25px;
			margin-left: auto;
			margin-right: auto;
			width: 45%;
		}
		@include media(">=tablet") {
			width: 410px;
			border-radius: 40px;
      margin-left: 40px;
      margin-right: 40px;
		}
		@include media("mac") {
			width: 372px;
			margin-bottom: 6px;
      margin-top: 14px;
		}
		&.flex{
			flex-wrap: nowrap;
			@include media(">=mobile-xs") {
				justify-content: center;
			}
		}
		h3{
			max-width: 136px;
      line-height: 22px;
			@include media(">=mobile-landscape") {
				font-size: 14px;
			}
			@include media(">=middle") {
				font-size: 16px;
				margin-bottom: 6px;
				max-width: 100%;
			}
			@include media(">=hd") {
				font-size: 17px;
			}
      @include media("mac") {
        margin-bottom: 4px;
      }
		}
		p{
			font-weight: 300;
			font-family: $lato;
			font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.78px;
			@include media(">=middle") {
				width: 300px;
			}
			@include media(">=hd") {
				width: 328px;
			}
		}
		img{
			margin-right: 14px;
			width: 42px;
			height: 42px;
			@include media(">=mobile-landscape") {
				margin-right: 18px;
				width: 50px;
				height: 50px;
			}
			@include media(">=middle") {
				margin-right: 20px;
				width: 55px;
				height: 55px;
			}
			@include media(">=hd") {
				margin-right: 22px;
				width: 60px;
				height: 60px;
			}
		}
	}

  .benefit-text {
    align-self: flex-start;
  }
