/* FOOTER */
	.footer{
		padding: 10px 0;
    width: 100%!important;
    background-color: #fff;
    position: relative;
    z-index: 5;
    text-align: center;
    margin-top: auto;
		@include media(">=laptop") {
			padding: 28px 0;
      height: 175px;
      .ru-lang & {
        height: 185px;
      }
		}
    .advertiser-page & {
        position: relative;
        .footer-top {
          margin-bottom: 0;
          padding-top: 28px;
          padding-bottom: 35px;
        }
    }
		button{
			margin-bottom: 10px;
			border: none;
			padding: 0;
			margin-left: 10px;
			position: relative;
			background-color: transparent;
			text-transform: uppercase;
			font-size: 13px;
			font-weight: 400;
			font-family: $helvetica;
			letter-spacing: 0.7px;
				@include media(">=middle") {
					&:before{
						content: '';
						position: absolute;
						background-color: #000;
						width: 0px;
						height: 0px;
						bottom: -1px;
						transition: width ease .5s;
					}
					&:hover::before{
						height: 2px;
						width: 100%;
					}
				}
		}
		nav{
			margin-top: 25px;
			@include media(">=laptop") {
				margin-top: 0;
			}
			a{
				text-transform: uppercase;
				font-size: 13px;
				font-weight: 400;
				letter-spacing: 0.7px;
				@include media(">=mobile-xs") {
					font-size: 13px;
				}
			}
			ul{
				align-items: flex-end;
			}
			li{
				margin-left: 10px;
				letter-spacing: 0.86px;
				margin-bottom: 28px;
				position: relative;
				width: 100%;
				font-weight: 700;
				@include media(">=tablet") {
					margin-right: 10px;
					width: auto;
				}
				@include media(">=middle") {
					width: auto;
					&:before{
						content: '';
						position: absolute;
						background-color: #000;
						width: 0px;
						height: 0px;
						bottom: -1px;
						transition: width ease .5s;
					}
					&:hover::before{
						height: 2px;
						width: 100%;
					}
				}
				@include media(">=laptop") {
					margin-bottom: 0;
				}
				@include media(">=hd") {
					margin: 0 0 0 60px;
				}
				a{
					color: #000;
				}
			}
		}
	}
	.footer-btns{
		flex-direction: column;
		align-items: flex-start;
		@include media(">=mobile-xs") {
			flex-direction: row;
			justify-content: flex-start;
		}
    a {
      font-family: $helvetica;
      font-size: 13px;
      margin-left: 0;
      color: #000;
      text-transform: uppercase;
      letter-spacing: 0.7px;
      @include media(">=laptop") {
        margin-bottom: 0;
      }
      &:first-child{
        margin-right: 20px;
        @include media(">=hd") {
          margin-right: 60px;
        }
      }
    }
		@include media(">=tablet") {
			width: 100%;
		}
		@include media(">=middle") {
			width: auto;
		}
	}
	.footer-top{
		align-items: center;
    justify-content: flex-start;
    display: inline-flex;
		@include media(">=middle") {
		}
		@include media(">=laptop") {
			margin-bottom: 30px;
		}
	}
	.footer-down{
		flex-direction: column;
		text-align: center;
		line-height: 1.71;
		letter-spacing: 0.15px;
    width: 100%;
		@include media(">=tablet") {
			flex-direction: row;
			align-items: center;
			justify-content: center;
			font-size: 16px;
		}
		a{
			color: #000;
			font-weight: 700;
		}
	}
	.footer-connect{
    font-weight: 400;
    font-size: 15px;
    margin-right: 190px;
		@include media(">=tablet") {
			width: 236px;
		}
	}
	.footer-social{
		margin-top: 30px;

		@include media(">=tablet") {
			margin: 0;
			width: 150px;
      position: relative;
      left: -88px;
		}
		p{
			font-weight: 400;
			line-height: 1.2;
			letter-spacing: 0.96px;
			margin-bottom: 10px;
      font-size: 16px;
      text-align: left;
		}
    a {
      width: 30px;
      height: 30px;
    }
		img{
			width: 23px;
			height: 23px;
			@include media(">=laptop") {
				width: 30px;
				height: 30px;
			}
		}
	}
	.footer-middle-icon{
		margin: 0 30px;
		@include media(">=laptop") {
			margin: 0 23px;
		}
	}
