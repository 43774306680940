.big-form {
  padding-top: 85px;
  padding-bottom: 106px;
}

.form-wrapper {
  margin: 0 auto;
	max-width: 660px;
	border-radius: 38px;
	background-color: #fff;
	width: 100%;
		input {
			width: 100%;
			height: 42px;
			border-radius: 21px;
			border: 2px solid #dedede;
			outline: none;
			padding: 16px;
				@include media("mac") {
					margin-bottom: 10px;
					height: 32px;
				}
				&:active,
				&:focus,
				&:hover {
					border-image-source: url(../img/button-border.svg);
					border-image-slice: 21;
					border-image-width: 21px;
					border-image-outset: 0;
					border-image-repeat: round round;
				}
		}
		textarea {
			width: 100%;
			border-radius: 21px;
			border: 2px solid #dedede;
			outline: none;
      padding: 10px;
				&:active,
				&:focus,
				&:hover {
					border-image-source: url(../img/button-border.svg);
					border-image-slice: 18;
					border-image-width: 21px;
					border-image-outset: 0;
					border-image-repeat: stretch;
				}
		}
}

.form-wrapper-header {
	background-image: linear-gradient(98deg, #1c0595 -49%, #b5e9ff 127%);
	border-top-left-radius: 38px;
	border-top-right-radius: 38px;
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
  @include media(">middle") {
    height: 80px;
  }
		h2 {
			font-size: 16px;
      font-family: $poppins;
      font-weight: 700;
      letter-spacing: 1.3px;
      @include media(">=middle") {
        font-size: 28px;
        letter-spacing: 2.28px;
      }
		}

}

.form-wrapper-body {
	padding: 45px 100px;
	color: #000;
    @include media("<tablet") {
      padding: 22px 22px 22px 31px;
    }
		h3 {
      font-family: $poppins;
			padding-left: 0px;
			margin-bottom: 8px;
      margin-bottom: 8px;
      margin-top: 17px;
      font-weight: 700;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: 0.69px;
      &:first-child {
        margin-top: 0;
      }
      @include media(">=middle") {
        padding-left: 20px;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.88px;
      }
		}
}

.vertical-variants-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.vertical-variant-item {
	width: 220px;
	height: 42px;
	border-radius: 21px;
	border: 2px solid #dedede;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 15px;
	padding: 0 10px;
  &:last-child {
    width: 100%;
  }
    @include media("<tablet") {
      margin-left: auto;
      margin-right: auto;
        &:last-child {
          width: 220px;
        }
    }
		p {
			font-size: 14px;
			line-height: 14px;
			text-transform: uppercase;
			font-weight: bold;
		}

		img {
			width: 30px;
			height: 30px;
			margin-right: 16px;
		}

		&:hover,
    &.active{
			border-image-source: url(../img/button-border.svg);
			border-image-slice: 21;
			border-image-width: 21px;
			border-image-outset: 0;
			border-image-repeat: round round;
		}
}

.selector-wrapper {

  position: relative;
  .current-option {
    width: 100%;
    height: 42px;
    border: 2px solid #dedede;
    border-radius: 21px;
    margin-bottom: 17px;
    line-height: 1;
    position: relative;
    z-index: 10;
    display: flex;
    padding-left: 10px;
    justify-content: flex-start;
    align-items: center;
    &::after{
      content: url(../img/dropdown-button.svg);
      position: absolute;
      width: 14px;
      height: 11px;
      right: 10px;
      top: 12px;
    }
    li {
      list-style-type: none;
      background-color: #ececec;
      font-size: 14px;
      padding-left: 5px;
      padding-right: 15px;
      letter-spacing: 0.48px;
      height: 24px;
      position: relative;
      white-space: nowrap;
      border-radius: 8px;
      position: relative;
      z-index: 10;
      margin-right: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
        &::after {
          content: url(../img/option-close-icon.svg);
          position: absolute;
          top: 5px;
          right: 3px;
        }
    }
  }

  .option-list {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border: 2px solid #dedede;
    padding: 42px 10px 11px 10px;
    margin: 0;
    border-radius: 21px;
    background-color: #fff;
    z-index: 5;
      li {
        list-style-type: none;
        font-size: 14px;
        letter-spacing: 0.48px;
        background-color: #fff;
        height: 22px;
        padding-left: 5px;
        padding-top: 2px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 11px;
        border-radius: 11px;
          &:hover {
            background-color: #ececec;
          }
      }
  }
  &.active {
    position: relative;
    z-index: 100;
    .option-list {
      display: block;
    }
  }
}

.yes-no-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 36px;
		button {
			width: 126px;
			height: 42px;
			border-radius: 21px;
			border: 2px solid #dedede;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #fff;
      outline: none;
				&:first-child{
					margin-right: 31px;
				}
				&:hover,
        &.active {
					border-image-source: url(../img/button-border.svg);
					border-image-slice: 21;
					border-image-width: 21px;
					border-image-outset: 0;
					border-image-repeat: round round;
				}
		}
}

.form-steps-control {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.next-form-step,
.prev-form-step {
  width: 126px;
  height: 42px;
  border-radius: 15px;
  background-image: linear-gradient(110deg, #1c0595 -51%, #b5e9ff 127%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #fff;
  border: none;
  position: relative;
  outline: none;
}

.prev-form-step {
	display: none;
}

.next-form-step{
	&::after{
  		content: url('../img/next-arrow-btn.svg');
  		position: absolute;
  		right: 10px;
  		top: 15px;
  	}
}

.prev-form-step{
	&::after{
  		content: url('../img/next-arrow-btn.svg');
  		position: absolute;
  		left: 10px;
  		top: 15;
  		transform: rotate(180deg);
  	}
}

.step{
	display: none;
		&.active{
			display: block;
		}
		@include media("mac") {
			h3{
				font-size: 16px;
			}
		}
}

.form-submit-wrapper {
  display: none;
  .contact-us-page & {
    display: block;
  }
}

.submit-btn {
  width: 126px;
  height: 42px;
  border: none;
  color: #fff;
  border-radius: 15px;
  background-image: linear-gradient(110deg, #1c0595 -51%, #b5e9ff 127%);
  font-weight: 400;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 38px;
  margin-bottom: 48px;
  outline: none;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;
}

.submit-caption {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.54px;
  text-align: center;
  @include media(">=middle") {
    font-size: 16px;
    line-height: 27px;
  }
}
