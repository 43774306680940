/* SLIDER REWIEVS */
	.slider-rewievs{
		padding-bottom: 25px;
		max-width: 970px;
		margin-left: auto;
		margin-right: auto;
    position: relative;
    .swiper-button-next {
      width: 20px;
      height: 22px;
      right: -30px;
      transform: translateY(-100%);
      img {
        width: 20px;
        height: 22px;
      }
    }
		@include media("mac") {
			padding-bottom: 0;
			margin-top: -3px;
		}
	}
	.swiper1-container{
		overflow: hidden;
		position: relative;
		.event-next{
			right: -13px;
		}
	}
	.review-txt{
		position: relative;
		font-family: $lato;
		max-width: 210px;
		margin: 0 auto;
		border-radius: 37px;
		background-color: rgba(0,0,0,.5);
    box-sizing: border-box;
		@include media(">=mobile-xs") {
			max-width: 90%;
			margin-left: 0;
		}
		@include media(">=mobile-landscape") {
			margin-top: 18px;
			max-width: 93%;
		}
		@include media(">=middle") {
			margin-top: 27px;
			padding: 11px 16px 20px 16px;
			max-width: 440px;
      height: 155px;
		}
		@include media(">=hd") {
			margin: 0 auto;
		}
		@include media("mac") {
			margin-top: 10px;
			margin-bottom: 20px;
			padding: 10px;
		}
		&:before{
			position: absolute;
			content: '';
				@include media(">=middle") {
					opacity: 0.17;
					border-radius: 37px;
					background-image: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0), #ffffff 106%);
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
			}
		}
		p.name-company{
			font-size: 14px;
			font-weight: 900;
			letter-spacing: 1.05px;
			@include media(">=tablet") {
				font-size: 16px;
			}
			@include media(">=middle") {
				font-size: 18px;
			}
		}
		blockquote{
			font-size: 13px;
			font-style: italic;
			line-height: 15px;
			letter-spacing: 0.44px;
			@include media(">=mobile-xs") {
				margin: 6px 0 12px 0;
			}
		}
		.company-member{
      display: inline-block;
			text-align: left;
			font-size: 14px;
      line-height: 17px;
			font-weight: 900;
			letter-spacing: 0.7px;
      position: absolute;
      &::before {
        content: '-';
        position: absolute;
        left: -10px;
        top: 0px;
      }
			@include media(">=tablet") {
				font-size: 13px;
			}
			@include media(">=middle") {
				font-size: 14px;
			}
		}
    &.review-1 {
      .company-member {
        bottom: 20px;
        left: 236px;
      }
    }
    &.review-2 {
      .company-member {
        bottom: 4px;
        left: 236px;
        .ru-lang & {
          bottom: -3px;
          left: 290px;
        }
      }
    }
    &.review-3 {
      padding-right: 43px;
      .company-member {
        bottom: 3px;
        left: 256px;
        .ru-lang & {
          left: 360px;
        }
      }
    }
    &.review-4 {
      .company-member {
        bottom: 13px;
        left: 224px;
        .ru-lang & {
          bottom: 4px;
        }
      }
    }
    &.review-5 {
      .company-member {
        bottom: 7px;
        left: 224px;
        .ru-lang & {
          left: 278px;
        }
      }
    }
	}
