/* STEPS */
	.steps{
		padding: 32px 0 62px;
    .container.content {
      margin-top: 0;
      margin-bottom: 0;
      @include media("highscreen") {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
		& .container .content-wrapper {
			@include media("mac") {
				padding-top: 0px;
				padding-bottom: 0;
			}
		}
		@include media(">=middle") {
			padding: 0;
			.container{
				height: auto;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-content: center;
			}
		}
		h2{
			@include media(">=laptop") {
				font-size: 40px;
				font-weight: 700;
        letter-spacing: 1.5px;
        margin-top: 51px;
			}
		}
	}
	.steps-wrap{
		margin-top: 44px;
		flex-direction: column;
		@include media(">=middle") {
			flex-direction: row;
			margin-top: 42px;
		}
		@include media(">=laptop") {
			margin-top: 43px;
		}
	}
	.step-item-wrap{
		display: block;
		margin-left: auto;
		margin-right: auto;
    position: relative;
	}
	.step-item{
		position: relative;
		margin-bottom: 25px;
		width: 223px;
		margin-left: auto;
		margin-right: auto;
		@include media(">=middle") {
			margin-bottom: 40px;
			width: 50%;
		}

		&:after{
			position: absolute;
			content: '';
			@include media(">=mobile-landscape") {
				background-image: url(../img/arrow-steps.svg);
				background-repeat: no-repeat;
				width: 85px;
				height: 75px;
				transform: rotate(50deg);
				right: -90px;
				bottom: -50px;
			}
		}
		&.one{
      .number {
        left: -14px;
      }
      p {
        margin-left: 0;
      }
      h3 {
        margin-left: 42px;
      }
			@include media(">=middle") {
				order: -1;
				margin-bottom: 10px;
			}
			&:after{
				@include media(">=middle") {
					transform: rotate(0deg);
					right: 20%;
					top: 38%;
				}
				@include media(">=laptop") {
					right: 24%;
				}
				@include media(">=hd") {
					right: 26%;
				}
				@include media(">=full-hd") {
					right: 28%;
				}
			}
		}
		&.two{
      margin-bottom: 31px;
      .number {
        left: -15px;
        .ru-lang & {
          left: 0px;
          top: -25px;
        }
      }
      h3 {
        margin-left: 56px;
      }
      span {
       @include media(">=middle") {
         margin-left: -10px;
       }
      }
			&:after{
				transform: scale(-1, 1) rotate(50deg);
				left: -70px;
				bottom: -40px;
				@include media(">=middle") {
					transform: scale(1, 1) rotate(110deg);
					left: auto;
					right: 40%;
					top: 110%;
				}
				@include media(">=laptop") {
					right: 52%;
          top: 117%;
				}
			}
		}
		&.three{
      .number {
        top: 0;
        left: -4px;
      }
      h3 {
        margin-left: 67px;
      }
      p {
        span {
          margin-left: 68px;
        }
      }
			&:after{
				@include media(">=middle") {
					left: 14%;
					top: -23%;
					transform: rotate(180deg);
				}
				@include media(">=laptop") {
					left: 19%;
				}
				@include media(">=hd") {
					left: 23%;
				}
				@include media(">=full-hd") {
					left: 24%;
					top: -6%;
				}
			}
		}
		&.four{
      .number {
        left: 71px;
        top: 0;
      }
      h3 {
        margin-left: 137px;
      }
      p {
        margin-left: 110px;
      }
			@include media(">=middle") {
				order: -1;
			}
			&:after{
				display: none;
			}
		}
		&.full{
			@include media(">=middle") {
				width: 100%;
			}
		}
		.number{
			position: absolute;
			top: -4px;
			font-size: 60px;
			font-family: $cyrvetica;
			font-weight: 400;
			line-height: 1;
			background: linear-gradient(to bottom, #9FD5F7 33%, #27179A 66%, #27179A);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			color: #A3DBFA;
			@include media(">=middle") {
				font-size: 85px;
				line-height: 79px;
			}
			@include media(">=hd") {
				font-size: 102px;
				line-height: 93px;
			}
		}
		h3{
			font-size: 14px;
			font-family: $lato;
			font-weight: 700;
			line-height: 1.29;
			letter-spacing: 2.05px;
			width: 183px;
			margin-left: 40px;
			margin-bottom: 6px;
			@include media(">=middle") {
				font-size: 18px;
				margin-left: 60px;
				margin-bottom: 7px;
				width: auto;
			}
			@include media(">=laptop") {
				font-size: 22px;
				margin-left: 48px;
			}
		}
		p{
			font-weight: 500;
			font-family: $lato;
			line-height: 1.07;
			letter-spacing: 0.26px;
			text-align: center;
			@include media(">=mobile-landscape") {
				width: 255px;
			}
			@include media(">=middle") {
				font-size: 16px;
				line-height: 1.56;
				letter-spacing: 0.75px;
				width: auto;
			}
			@include media("mac") {
				font-size: 14px;
				line-height: 1.2;
			}
		}
	}
