/* ABOUT2 */
	.about2{
		padding: 50px 0 60px;
    .container.content {
      margin-top: 80px;
    }
		@include media(">=middle") {
			padding: 0;
			.container{
				height: auto;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-content: center;
        flex-basis: 100%;
          &.content {
            justify-content: space-around;
          }
			}
		}
		h2{
			font-size: 30px;
			font-weight: bold;
			line-height: 1.27;
			letter-spacing: 1.02px;
			margin-bottom: 24px;
			text-transform: none;
			@include media(">=mobile-xs") {
				font-size: 31px;
			}
			@include media(">=mobile-landscape") {
				font-size: 38px;
			}
			@include media(">=tablet") {
				font-size: 45px;
			}
			@include media(">=middle") {
				font-size: 52px;
			}
			@include media(">=laptop") {
				font-size: 60px;
        margin-bottom: 70px;
			}
			@include media(">=hd") {
				font-size: 66px;
				font-weight: bold;
				line-height: 0.81;
				letter-spacing: 2.45px;
			}
		}
		p{
			text-align: center;
			max-width: 240px;
			margin-left: auto;
			margin-right: auto;
			font-size: 13px;
			line-height: 1.62;
			letter-spacing: 0.66px;
			margin-bottom: 30px;
      &:last-of-type {
        margin-bottom: 0;
      }
			@include media(">=mobile-xs") {
				max-width: 920px;
				font-size: 14px;
			}
			@include media(">=mobile-landscape") {
				font-size: 16px;
			}
			@include media(">=middle") {
				font-size: 18px;
			}
			@include media(">=hd") {
				font-size: 17px;
				line-height: 1.4;
				letter-spacing: 1.53px;
			}
		}
		.btn-cta{
			width: 94px;
      margin: auto;
			justify-content: center;
			height: 30px;
			position: relative;
			font-size: 14px;
			border: 2px solid transparent;
			font-family: $poppins;
			transition: all ease .5s;
			line-height: 29px;
			@include media(">=mobile-landscape") {
				width: 114px;
				height: 36px;
				border-radius: 7px;
				background-color: #252525;
				font-size: 18px;
				line-height: 30px;
				letter-spacing: 0.61px;
        .ru-lang & {
          width: 190px;
        }
			}
			@include media(">=laptop") {
				line-height: 31px;
			}
			&:hover{
				background-color: #FFF;
				border: 2px solid #000;
				color: #000;
				&:after{
					right: -25px;
				}
			}
			&:after{
				content: '';
				position: absolute;
				right: -21px;
				top: 8px;
				background-image: url(../img/arrow.svg);
				background-size: cover;
				background-repeat: no-repeat;
				background-position: 0 50%;
				transition: all ease .1s;
				width: 11px;
				height: 12px;
				@include media(">=mobile-landscape") {
					top: 10px;
				}
			}
		}
	}
