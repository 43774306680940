.interactive-bg {
  position: fixed;
  left: 0px;
  top: 0px;
  overflow: hidden;
  width: 100%;
  height: 100%;

  canvas {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      transition: 1000ms cubic-bezier(0.645, 0.045, 0.355, 1);
    }


  .overlay {
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: transparent;
    transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
    backface-visibility: hidden;
    transition: background 1000ms;
      &.light-bg {
        &::before {
          background: url(../img/dot-light.png) repeat;
          background-size: 12px;
        }
      }
  }

  .overlay:before {
    content: "";
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: url(../img/dot.png) repeat;
    background-size: 12px;
    transition: 1000ms;
    transition-property: opacity;
  }

}
